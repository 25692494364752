
import { Diagram, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import * as diff from '@/modules/editor/helpers/diff'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'

import { DiagramModule } from '../store'

@Component({
  name: 'DiagramMergeMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly diagram!: Diagram
  @Prop() readonly permission!: PermissionType

  currentTab: 'all' | 'created' | 'updated' | 'removed' = 'all'

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get diagramContent () {
    return this.diagramModule.diagramContents[this.diagram.id]
  }

  get tabs () {
    return [
      {
        caption: this.createdChangeDescriptions.length + this.updateChangeDescriptions.length + this.removeChangeDescriptions.length,
        id: 'all',
        text: 'All'
      },
      {
        caption: this.createdChangeDescriptions.length,
        icon: '$fas-plus',
        iconColor: 'green',
        id: 'created',
        text: 'Created'
      },
      {
        caption: this.updateChangeDescriptions.length,
        icon: '$fas-sync-alt',
        iconColor: 'info',
        id: 'updated',
        text: 'Updated'
      },
      {
        caption: this.removeChangeDescriptions.length,
        icon: '$fas-times',
        iconColor: 'error',
        id: 'removed',
        text: 'Removed'
      }
    ]
  }

  get currentChanges () {
    let descriptions: diff.IDiffDescription[] = []
    if (this.currentTab === 'all') {
      descriptions = this.allChangeDescriptions
    } else if (this.currentTab === 'created') {
      descriptions = this.createdChangeDescriptions
    } else if (this.currentTab === 'updated') {
      descriptions = this.updateChangeDescriptions
    } else if (this.currentTab === 'removed') {
      descriptions = this.removeChangeDescriptions
    }

    const tasksProposed = this.diagramContent?.tasksProposed || []
    return descriptions.map(o => {
      const taskProposed = tasksProposed.find(t => 'id' in t.task ? t.task.id === o.id : false)
      return {
        description: o,
        taskProposed
      }
    })
      .sort((a, b) => a.taskProposed && b.taskProposed ? b.taskProposed.proposedAt.localeCompare(a.taskProposed.proposedAt) : 0)
  }

  get allChangeCount () {
    return this.allChangeDescriptions.length
  }

  get allChangeDescriptions () {
    return diff.describeChanges(this.editorModule.draftChanges, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  get createdChangeDescriptions () {
    return diff.describeChanges({
      created: this.editorModule.draftChanges.created
    }, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  get updateChangeDescriptions () {
    return diff.describeChanges({
      updated: this.editorModule.draftChanges.updated
    }, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  get removeChangeDescriptions () {
    return diff.describeChanges({
      removed: this.editorModule.draftChanges.removed
    }, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }
}
