
import { PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import findNextName from '@/helpers/find-next-name'
import { AlertModule } from '@/modules/alert/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import { DiagramModule } from '../store'
import DiagramContextMenu from './diagram-context-menu/index.vue'
import NameEdit from './name-edit.vue'
import Status from './status.vue'

@Component({
  components: {
    DiagramContextMenu,
    NameEdit,
    OrganizationUpgradeMenu,
    Status
  },
  name: 'DiagramStatusMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType

  editingDiagramId: string | null = null
  optionsMenuItemId: string | null = null
  hoverDiagramId: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get diagramDeleteDialog () {
    return this.$queryValue('diagram_delete_dialog')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentOrganizationLimitDiagramDraftsReached () {
    return this.currentDiagramDrafts.length >= this.currentOrganizationLimits.diagramDrafts
  }

  get parentDiagram () {
    return this.currentDiagram?.parentId ? this.diagramModule.diagrams[this.currentDiagram.parentId] : this.currentDiagram
  }

  get parentDiagramContent () {
    return this.parentDiagram ? this.diagramModule.diagramContents[this.parentDiagram.id] : undefined
  }

  get currentDiagramDrafts () {
    return Object.values(this.diagramModule.diagrams).filter(o => o.parentId === this.parentDiagram?.id)
  }

  get showDiagramName () {
    return this.currentDiagram?.status === 'draft'
  }

  createDraftDiagram () {
    const parentDiagram = this.parentDiagram
    const parentDiagramContent = this.parentDiagramContent
    if (!parentDiagram || !parentDiagramContent) {
      return
    }

    const tasks: Task[] = []
    const revertTasks: Task[] = [{
      route: this.$route,
      type: 'navigation'
    }]

    const nextItemIndex = Object.values(this.currentDiagramDrafts).reduce((p, c) => c.index > p ? c.index : p, 0) + 1

    const { diagram, diagramUpsert } = this.diagramModule.generateDiagram(this.currentLandscape.id, this.currentVersion.id, {
      description: parentDiagram.description,
      groupId: parentDiagram.groupId,
      index: nextItemIndex,
      modelId: parentDiagram.modelId,
      name: findNextName('Draft ', this.currentDiagramDrafts),
      parentId: parentDiagram.id,
      status: 'draft',
      type: parentDiagram.type
    })
    const { diagramContent, diagramContentUpsert } = this.diagramModule.generateDiagramContent(this.currentLandscape.id, this.currentVersion.id, diagram, {
      connections: parentDiagramContent.connections,
      objects: parentDiagramContent.objects
    })

    this.diagramModule.setDiagramVersion(diagram)
    this.diagramModule.setDiagramContentVersion(diagramContent)

    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpsert({
        diagramId: diagram.id,
        landscapeId: this.currentLandscape.id,
        props: {
          ...diagramUpsert,
          ...diagramContentUpsert
        },
        updateViewedAt: true,
        versionId: this.currentVersion.id
      })
    })

    tasks.push({
      id: diagram.id,
      props: {
        ...diagramUpsert,
        ...diagramContentUpsert
      },
      type: 'diagram-create'
    })

    const func: (() => Promise<any>)[] = []

    Object
      .values(this.flowModule.flows)
      .filter(o => o.diagramId === parentDiagram.id)
      .slice(0, this.currentOrganizationLimits.flowsPerDiagram)
      .forEach(o => {
        const { flow, flowUpsert } = this.flowModule.generateFlow(o.landscapeId, o.versionId, {
          diagramId: diagram.id,
          handleId: o.handleId,
          labels: o.labels,
          name: o.name,
          showConnectionNames: o.showConnectionNames,
          steps: o.steps
        })
        this.flowModule.setFlowVersion(flow)

        func.push(() => this.flowModule.flowUpsert({
          flowId: flow.id,
          landscapeId: this.currentLandscape.id,
          props: flowUpsert,
          versionId: this.currentVersion.id
        }))

        tasks.push({
          id: flow.id,
          props: flowUpsert,
          type: 'flow-create'
        })
        revertTasks.push({
          id: flow.id,
          type: 'flow-delete'
        })
      })

    this.editorModule.addToTaskQueue({ func })

    revertTasks.push({
      id: diagram.id,
      type: 'diagram-delete'
    })

    tasks.push({
      route: this.$route,
      type: 'navigation'
    })

    this.$pushQuery({
      diagram: diagram.handleId
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })
  }
}
