import { DiagramStatus, TagColor } from '@icepanel/platform-api-client'

export interface IDiagramStatus {
  backgroundColor: TagColor
  color: TagColor
  icon: string | null
  name: string
  tooltip: string
}

const statuses: Record<DiagramStatus, IDiagramStatus> = {
  current: {
    backgroundColor: 'grey',
    color: 'white',
    icon: null,
    name: 'Current',
    tooltip: 'Current diagram and model'
  },
  draft: {
    backgroundColor: 'yellow',
    color: 'yellow',
    icon: '$fas-edit',
    name: 'Draft',
    tooltip: 'Draft diagram with pending model changes'
  }
}

export default statuses
