
import { FormController, validationRules } from '@icepanel/app-form'
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'

import { DiagramModule } from '../store'

@Component({
  name: 'DiagramNameEdit'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)

  @Prop() readonly diagramId!: string

  get diagram () {
    return this.diagramModule.diagrams[this.diagramId]
  }

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      if (this.diagram.name === model.name) {
        return
      }

      const revertTasks: Task[] = [{
        id: this.diagram.id,
        props: {
          name: this.diagram.name
        },
        type: 'diagram-update'
      }]

      const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(this.diagram.id, {
        name: model.name
      })
      this.diagramModule.setDiagramVersion(diagram)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramUpdate({
          diagramId: this.diagram.id,
          landscapeId: this.diagram.landscapeId,
          props: diagramUpdate,
          versionId: this.diagram.versionId
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: this.diagram.id,
          props: diagramUpdate,
          type: 'diagram-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
    this.formController.on('success', () => {
      this.$emit('success')
    })

    this.formController.setInitialModel({
      name: this.diagram.name
    })
    this.formController.resetModel()
  }
}
