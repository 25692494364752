
import { Context } from '@icepanel/app-canvas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

const VIEW_EMPTY_CHECK_INTERVAL = 1000

@Component({
  name: 'DiagramLostAlert'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly view?: Context.Diagram.View

  empty = false
  emptyCheckInterval?: number

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  mounted () {
    this.emptyCheckInterval = window.setInterval(() => {
      if (this.view) {
        const { objects, connections, comments, objectsInView, connectionsInView, commentsInView } = this.view
        if (
          objects &&
          connections &&
          comments &&
          objectsInView &&
          connectionsInView &&
          commentsInView &&
          (Object.values(objects).length || Object.values(connections).length || Object.values(comments).length) &&
          !objectsInView.length &&
          !connectionsInView.length &&
          !commentsInView.length
        ) {
          this.empty = true
        } else {
          this.empty = false
        }
      }
    }, VIEW_EMPTY_CHECK_INTERVAL)
  }

  destroyed () {
    clearInterval(this.emptyCheckInterval)
  }
}
