
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import markdownPreview from '@/helpers/markdown-preview'

import { DiagramModule } from '../store'
import Date from './diagram-date.vue'
import DiagramMergeMenu from './merge-menu.vue'
import NameEdit from './name-edit.vue'
import DiagramStatus from './status.vue'
import DiagramStatusMenu from './status-menu.vue'

@Component({
  components: {
    Date,
    DiagramMergeMenu,
    DiagramStatus,
    DiagramStatusMenu,
    NameEdit
  },
  name: 'DiagramInfo'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)

  @Prop() readonly permission!: PermissionType

  editing = false

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get parentDiagram () {
    return this.currentDiagram?.parentId ? this.diagramModule.diagrams[this.currentDiagram.parentId] : this.currentDiagram
  }

  get diagramPreview () {
    return this.currentDiagram ? markdownPreview(this.currentDiagram.description) : undefined
  }

  startEditing () {
    if (this.permission !== 'read') {
      this.editing = true
    }
  }
}
