
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import { Diagram, DiagramStatus } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import hexToRgb from '@/helpers/hex-to-rgb'

import diagramStatuses from '../helpers/status'

interface IStatus {
  active: boolean
  backgroundColor: string
  color: string
  icon: string | null
  id: DiagramStatus
  name: string,
  tooltip?: string
}

@Component({
  name: 'DiagramStatus'
})
export default class extends Vue {
  @Prop() readonly diagram!: Diagram
  @Prop() readonly tooltipProps: any
  @Prop({ default: true }) readonly showStatus!: boolean
  @Prop({ default: false }) readonly showName!: boolean

  get statuses (): IStatus[] {
    const currentColor = hexToRgb(TAG_COLOR_ACTIVE[diagramStatuses.current.color])
    const draftColor = hexToRgb(TAG_COLOR_ACTIVE[diagramStatuses.draft.color])
    return [
      {
        active: this.diagram.status === 'current',
        backgroundColor: `rgba(${currentColor?.r}, ${currentColor?.g}, ${currentColor?.b}, 0.2)`,
        color: TAG_COLOR_ACTIVE[diagramStatuses.current.color],
        icon: diagramStatuses.current.icon,
        id: 'current',
        name: diagramStatuses.current.name,
        tooltip: diagramStatuses.current.tooltip
      },
      {
        active: this.diagram.status === 'draft',
        backgroundColor: `rgba(${draftColor?.r}, ${draftColor?.g}, ${draftColor?.b}, 0.2)`,
        color: TAG_COLOR_ACTIVE[diagramStatuses.draft.color],
        icon: diagramStatuses.draft.icon,
        id: 'draft',
        name: diagramStatuses.draft.name,
        tooltip: diagramStatuses.draft.tooltip
      }
    ]
  }

  get currentStatus () {
    return this.statuses.find(o => o.active)
  }
}
